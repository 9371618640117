import { FC } from "react";

import { Button } from "components";

import styles from "./Description.module.scss";

const Description: FC = () => (
  <div className={styles.description}>
    <h1 className={styles.title}>
      In order to check you wallet please follow the next simple steps:
    </h1>

    <p className={styles.step}>1. Connect with Metamask or WalletConnect</p>
    <p className={styles.step}>2. Click on Check Account button</p>
    <p className={styles.step}>
      3. Confirm the action in pop-up window (signing transaction will not cost
      any additional fees)
    </p>
    <h3 className={styles.warning}>
      Please note, you need to have at least 150 UFI in your wallet to perform
      the check.
    </h3>
    <div className={styles.links}>
      <Button
        href="https://pancakeswap.finance/swap?outputCurrency=0xe2a59D5E33c6540E18aAA46BF98917aC3158Db0D"
        target="_blank"
      >
        Buy UFI on Pancakeswap
      </Button>
      <Button href="https://www.gate.io/ru/trade/UFI_USDT" target="_blank">
        Buy UFI on Gate.io
      </Button>
    </div>
  </div>
);

export default Description;
