import clsx from "clsx";

import { Dropdown, Menu, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { Network } from "models";
import { Button } from "components/index";

import styles from "./NetworksDropdown.module.scss";

type NetworksDropdownProps = {
  menu: Network[];
  value: Network;
  onChange: (network: Network) => void;
};

const NetworksDropdown = ({ menu, value, onChange }: NetworksDropdownProps) => {
  const handleChange = (menuItem: Network) => () => {
    onChange(menuItem);
  };

  const options = (
    <Menu>
      {menu.map((menuItem) => {
        const { name, networkId, avatarContained } = menuItem;

        return (
          <Menu.Item
            key={networkId}
            icon={<Avatar size="small" src={avatarContained} />}
            onClick={handleChange(menuItem)}
            className={clsx(styles.menuItem, {
              [styles.activeMenu]: networkId === value.networkId,
            })}
          >
            {name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={options}
      trigger={["click"]}
      overlayClassName={styles.dropdown}
    >
      <Button
        icon={<Avatar size="small" src={value.avatar} />}
        className={styles.button}
      >
        {value.name} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default NetworksDropdown;
