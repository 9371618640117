import React, { FC } from "react";
import { Avatar } from "antd";

import styles from "./Button.module.scss";

interface HeaderButtonProps {
  avatar?: string;
  onClick: () => void;
  children: Record<string, React.ReactNode>;
}

const HeaderButton: FC<HeaderButtonProps> = (props) => {
  const { avatar, onClick, children } = props;
  return (
    <button type="button" className={styles.button} onClick={onClick}>
      {avatar && <Avatar src={avatar} className={styles.avatar} />}
      <span className={styles.left}>{children.left}</span>
      <span className={styles.right}>{children.right}</span>
    </button>
  );
};

export default HeaderButton;
