import { ReactNode } from "react";

import { Layout as AntdLayout } from "antd";

import { useWallet } from "hooks";
import { Header, Loader, Alert, Description } from "components";

import styles from "./Layout.module.scss";

const { Content } = AntdLayout;

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const { connected, connecting } = useWallet();

  const renderContent = () => {
    if (connecting) {
      return (
        <>
          <Description />
          <Loader />;
        </>
      );
    }

    if (connected) {
      return (
        <>
          <Description />
          {children}
        </>
      );
    }

    return (
      <>
        <div className={styles.alert}>
          <Alert type="error">Please connect your wallet to proceed</Alert>
        </div>
        <Description />
      </>
    );
  };

  return (
    <AntdLayout className={styles.layout}>
      <Header />
      <Content className={styles.content}>{renderContent()}</Content>
    </AntdLayout>
  );
};

export default Layout;
