const storage = window.localStorage;

const getValue = (key: string): string | null => storage.getItem(key);

const removeValue = (key: string): void => {
  storage.removeItem(key);
};

const addValue = (key: string, value: string): void => {
  storage.setItem(key, value);
};

const storageObject = { getValue, removeValue, addValue };

export default storageObject;
