import React, { FC } from "react";

import { useModal } from "hooks";
import { Network, WalletType } from "models";

import {
  Button,
  Modal,
  NumericText,
  WalletsModal,
  DisconnectWalletModal,
} from "components";

import { getShortWalletAddress } from "helpers/formatting";

import metamaskLogo from "assets/metamask.svg";
import walletConnectLogo from "assets/wallet-connect.svg";

import { HeaderButton } from "../HeaderButton";

import styles from "./WalletAccount.module.scss";

interface AccountProps {
  connecting: boolean;
  connected: boolean;
  account: string;
  balance: string;
  walletType: string;
  networkId: number | null;
  activeNetwork: Network;
}

const avatars = {
  [WalletType.metamask as string]: metamaskLogo,
  [WalletType.walletConnect as string]: walletConnectLogo,
};

const WalletAccount: FC<AccountProps> = (props) => {
  const {
    connecting,
    connected,
    account,
    balance,
    walletType,
    networkId,
    activeNetwork,
  } = props;

  const shortWalletAddress = getShortWalletAddress(account);

  const { isOpen, onOpen, onClose } = useModal();

  const isConnected = !connecting && connected;
  const isDisconnected = !connecting && !connected;

  return (
    <>
      {isConnected && (
        <>
          <HeaderButton avatar={avatars[walletType]} onClick={onOpen}>
            {{
              left: (
                <NumericText
                  value={networkId !== activeNetwork.networkId ? 0 : balance}
                  precision={4}
                  suffix={activeNetwork.symbol}
                />
              ),
              right: <>{shortWalletAddress}</>,
            }}
          </HeaderButton>
          <Modal visible={isOpen} onCancel={onClose} width={460}>
            <DisconnectWalletModal network={activeNetwork} onClose={onClose} />
          </Modal>
        </>
      )}
      {isDisconnected && (
        <>
          <Button onClick={onOpen} className={styles.connectButton}>
            Connect Wallet
          </Button>

          <Modal visible={isOpen} onCancel={onClose} width={400}>
            <WalletsModal onClose={onClose} />
          </Modal>
        </>
      )}
    </>
  );
};

export default WalletAccount;
