import { useEffect, useCallback, useState } from "react";
import { Col, Row } from "antd";
import { CheckRiskResponse } from "@purefi/verifier-sdk";
import { PureFIWidgetConfig } from "@purefi/verifier-widget/lib/models";

import { useWallet, useQuery, useModal } from "hooks";
import { loadScript } from "utils/gleam";

import { Modal, CongratulationsModal, Widget } from "components";

const generateCode = (address: string, score: number) =>
  `PUREFI-${address.slice(-2)}${score}${address.slice(2, 4)}`;

const Gleam = () => {
  const query = useQuery();
  const [code, setCode] = useState("");
  const { networkId, activeNetwork } = useWallet();
  const { isOpen, onOpen, onClose } = useModal();
  const isAvailableNetwork = networkId === activeNetwork.networkId;

  const gleamHandler = useCallback(
    (
      response: CheckRiskResponse[],
      thresholds: PureFIWidgetConfig["riskThresholds"]
    ) => {
      try {
        const { Gleam: tracker } = window as any;
        const trackingName = query.get("trackingName");
        const score = response[0].riskScore;

        if (
          tracker &&
          trackingName &&
          typeof score !== "undefined" &&
          score !== null
        ) {
          let status = "medium";

          if (score <= thresholds.low) {
            status = "low";
          }

          if (score >= thresholds.high) {
            status = "high";
          }

          const newCode = generateCode(response[0].address, score);
          setCode(newCode);

          tracker?.push([
            [trackingName, `${response[0].address} ${status} (${score}%)`],
            ["callback", onOpen],
          ]);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [onOpen]
  );

  const backHandler = useCallback(() => {
    const returnUrl = query.get("returnUrl");
    if (returnUrl) {
      document.location.href = returnUrl;
    } else {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    const key = query.get("key");
    if (key) {
      loadScript(key);
    }
  }, []);

  return (
    <>
      {isAvailableNetwork && (
        <>
          <Row justify="center">
            <Col>
              <Widget onSuccess={gleamHandler} />
            </Col>
          </Row>
          <Modal visible={isOpen} onCancel={onClose} centered>
            <CongratulationsModal onClose={backHandler} code={code} />
          </Modal>
        </>
      )}
    </>
  );
};

export default Gleam;
