import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { createExplorerLink } from "@metamask/etherscan-link";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { Network } from "models";
import {
  DEFAULT_NETWORK,
  BSC_MAINNET_NETWORK,
  POLYGON_MAINNET_NETWORK,
  BSC_TEST_NETWORK,
  POLYGON_TESTNET_NETWORK,
  NETWORKS,
} from "config";

class Ethereum {
  private metaMaskWallet = (window as any).ethereum;

  private provider =
    this.metaMaskWallet &&
    new ethers.providers.Web3Provider(this.metaMaskWallet);

  private account: string = "";

  private network: Network = DEFAULT_NETWORK;

  init = (
    walletProvider: null | WalletConnectProvider,
    accountsChanged: (account: string) => void,
    networkChanged: (chainId: number) => void
  ): void => {
    const newProvider = walletProvider || this.metaMaskWallet;
    this.provider = new ethers.providers.Web3Provider(newProvider);

    newProvider.on("accountsChanged", ([account]: string[]) => {
      this.account = account || "";
      accountsChanged(this.account);
    });

    const provider = new ethers.providers.Web3Provider(newProvider, "any");
    provider.on("network", ({ chainId }) => {
      // Set current network SC address
      this.network = NETWORKS[chainId];

      // Update provider when network changed
      this.provider = new ethers.providers.Web3Provider(newProvider);

      networkChanged(chainId);
    });
  };

  getMetaMaskWalletAccount = async (request: boolean): Promise<string> => {
    const method = request ? "eth_requestAccounts" : "eth_accounts";
    const [account] = await this.metaMaskWallet.request({
      method,
    });
    return account || "";
  };

  isMetaMaskProviderExist = async (): Promise<boolean> => {
    const provider = await detectEthereumProvider();
    return !!provider;
  };

  setWalletAccount = (account: string): void => {
    this.account = account;
  };

  setNetwork = (newNetwork: Network): void => {
    this.network = newNetwork;
  };

  getBalance = async (): Promise<string> => {
    const balance = await this.provider.getBalance(this.account);
    return ethers.utils.formatEther(balance);
  };

  getNetwork = async (): Promise<number> => {
    const { chainId } = await this.provider.getNetwork();
    return chainId;
  };

  getSigner = () => this.provider.getSigner();

  getExplorerLink = (hash: string): string => {
    const { networkId } = this.network;

    if (networkId === BSC_MAINNET_NETWORK.networkId) {
      return `https://bscscan.com/tx/${hash}`;
    }

    if (networkId === POLYGON_MAINNET_NETWORK.networkId) {
      return `https://polygonscan.com/tx/${hash}`;
    }

    if (networkId === BSC_TEST_NETWORK.networkId) {
      return `https://testnet.bscscan.com/tx/${hash}`;
    }

    if (networkId === POLYGON_TESTNET_NETWORK.networkId) {
      return `https://mumbai.polygonscan.com/tx/${hash}`;
    }

    return createExplorerLink(hash, networkId.toString());
  };
}

const ethereum = new Ethereum();

export default ethereum;
