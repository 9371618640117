import { FC } from "react";
import { Row, Col } from "antd";
import { Button } from "components";
import copySrc from "assets/action-copy.svg";
import styles from "./CongratulationsModal.module.scss";

type CongratulationsModalProps = {
  onClose: () => void;
  code: string;
};

const CongratulationsModal: FC<CongratulationsModalProps> = (props) => {
  const { onClose, code } = props;

  const handleCopy = () => {
    const temp = document.createElement("textarea");
    temp.value = code;
    document.body.appendChild(temp);
    temp.select();
    temp.setSelectionRange(0, 99999);
    navigator?.clipboard.writeText(temp.value);
    document.body.removeChild(temp);
  };

  return (
    <div className={styles.modal}>
      <h4 className={styles.title}>Congratulations!</h4>

      <p className={styles.description}>
        You have just completed the AML Check
      </p>

      <div className={styles.hint}>
        Enter this unique code within the Gleam form
      </div>
      <div className={styles.codeWrapper}>
        <div className={styles.code}>
          {code}
          <button
            type="button"
            className={styles.action__button}
            onClick={handleCopy}
          >
            <img src={copySrc} alt="copy" />
          </button>
        </div>
      </div>

      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Button onClick={onClose} className={styles.button}>
            Back
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CongratulationsModal;
