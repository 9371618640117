import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./Loader.module.css";

const Loader = () => (
  <Spin indicator={<LoadingOutlined spin />} className={styles.spin} />
);

export default Loader;
