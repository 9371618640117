export enum WalletStatus {
  connecting = "connecting",
  connected = "connected",
  disconnected = "disconnected",
}

export enum WalletType {
  metamask = "metamask",
  walletConnect = "walletConnect",
  none = "",
}

export enum PoolState {
  active = "active",
  upcoming = "upcoming",
  ended = "ended",
}

export enum RiskStatus {
  OK = "ok",
  WARNING = "warning",
  DANGER = "danger",
  LOCKED = "locked",
  NOT_CHECKED = "not checked",
  NEED_TOKENS = "insufficient UFI balance",
}
