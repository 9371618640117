import { FC, useEffect, useRef } from "react";
import { PureFIWidget } from "@purefi/verifier-widget";
import { PureFISuccessCallback } from "@purefi/verifier-widget/lib/models";
import "./Widget.scss";

interface WidgetProps {
  onSuccess: PureFISuccessCallback;
}

const Widget: FC<WidgetProps> = (props) => {
  const { onSuccess } = props;
  const widgetRef = useRef(null);

  useEffect(() => {
    const widgetInstance = widgetRef.current;
    if (widgetRef.current) {
      PureFIWidget.render(
        widgetRef.current,
        {
          riskThresholds: {
            low: 25,
            high: 75,
          },
          i18n: {
            status: {
              check: "Check Account",
            },
          },
          shouldCheckSignerAddress: true,
          shouldFlushOnSignerChange: true,
          shouldShowNotice: false,
          shouldCloseModalOnOverlayClick: true,
          shouldShowUserRejectedError: false,
          shouldShowMissingSignerError: true,
        },
        onSuccess
      );
    }
    return () => {
      PureFIWidget.unmount(widgetInstance);
    };
  }, []);

  return <div ref={widgetRef} />;
};

export default Widget;
