import clsx from "clsx";
import { Row, Col, Tooltip, Collapse } from "antd";
import { FC, useState, useRef, useEffect } from "react";

import { RISK } from "config";
import { Risk } from "models";
import { Button } from "components";

import { CaretRightOutlined } from "@ant-design/icons";

import successIcon from "assets/risk/success.svg";
import warningIcon from "assets/risk/warning.svg";
import errorIcon from "assets/risk/error.svg";
import infoIcon from "assets/risk/info.svg";
import chevronUpIcon from "assets/risk/chevronUp.svg";
import chevronDownIcon from "assets/risk/chevronDown.svg";

import "./ArrayRiskModal.scss";

const { Panel } = Collapse;

interface IRiskData extends Risk {
  isContract: boolean;
}

interface IRiskModalProps {
  riskData: IRiskData[];
  downloadReport(): void;
}

interface IData {
  riskData: IRiskData;
  statusClassName: string;
  statusIcon: string;
  statusText: string;
}

const ArrayRiskModal: FC<IRiskModalProps> = ({ riskData, downloadReport }) => {
  let data: IData[] = [];
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (!open) {
      wrapperRef.current?.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [open]);

  if (riskData.length) {
    data = riskData.map((item) => {
      const { isContract = false } = item;
      let score = item.score as number;

      let statusIcon = "";
      let statusText = "";
      let statusClassName = "";

      if (item.score !== null) {
        score = item.score as number;
        const isLow = score <= RISK.lowThreshold;
        const isMedium =
          score > RISK.lowThreshold && score < RISK.highThreshold;
        const isHigh = score >= RISK.highThreshold;

        statusClassName = clsx(
          "status",
          isLow && "low",
          isMedium && "medium",
          isHigh && "high"
        );

        const titleStatus = isContract ? "Contract" : "Your";
        if (isLow) {
          statusText = `${titleStatus} ML risk is Low`;
          statusIcon = successIcon;
        } else if (isMedium) {
          statusText = `${titleStatus} ML risk is Medium`;
          statusIcon = warningIcon;
        } else if (isHigh) {
          statusText = `${titleStatus} ML risk is High`;
          statusIcon = errorIcon;
        }
      }

      return {
        riskData: {
          ...item,
          score,
          isContract,
        },
        statusClassName,
        statusText,
        statusIcon,
      };
    });
  }

  const toggleHandler = () => {
    setOpen((prev) => !prev);
  };

  const wrapperClassName = clsx("wrapper", open && "overflow");

  return (
    <>
      <h4 className="title">ML Risk Score</h4>
      <Row gutter={[0, 16]} className="row">
        <Col span={24}>
          <Collapse
            expandIconPosition="right"
            accordion
            ghost
            bordered={false}
            expandIcon={({ isActive, collapsible }) => {
              if (collapsible === "disabled") {
                return null;
              }
              return isActive ? (
                <CaretRightOutlined className="expandIcon" rotate={90} />
              ) : (
                <div className="expandIcon">View Details</div>
              );
            }}
          >
            {data.map(
              ({ statusIcon, statusClassName, statusText, riskData: risk }) => {
                const header = statusIcon && (
                  <div className="statusWrapper">
                    <div>
                      <img src={statusIcon} alt="status" />
                    </div>
                    <div className={statusClassName}>{statusText}</div>
                  </div>
                );

                const connections = Object.entries(risk.connections)
                  .filter(
                    ([key, value]) => value > 0 && key in RISK.connectionsDesc
                  )
                  .map(([key, value]) => {
                    const desc = (
                      RISK.connectionsDesc as Record<
                        string,
                        Record<string, string>
                      >
                    )[key];
                    return {
                      label: desc.label,
                      name: key,
                      helpText: desc.helpText,
                      value,
                    };
                  })
                  .sort((a, b) => b.value - a.value);

                return (
                  <Panel
                    header={header}
                    className="collapseItem"
                    collapsible={Number(risk.score) > 0 ? "header" : "disabled"}
                    key={risk.isContract ? "contract" : "your wallet"}
                  >
                    <div className="connections">
                      <div className="riskLevel">
                        <div className="safe legend">Low</div>
                        <div className="scale">
                          <div
                            className="pointer"
                            style={{
                              left: `calc(${risk.score}% - 4px)`,
                            }}
                          >
                            <div className="relative">
                              <div>
                                <div className="arrowUp" />
                                <div
                                  className="hint"
                                  style={{
                                    left: risk.isContract ? "-27px" : "-32px",
                                  }}
                                >
                                  {risk.isContract ? "Contract" : "Your wallet"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="risky legend">High</div>
                      </div>
                      <div className="header">
                        Connections of{" "}
                        {risk.isContract ? "contract" : "your wallet"}:
                      </div>
                      {Object.keys(risk.connections).length > 0 && (
                        <>
                          <div className={wrapperClassName}>
                            {Array.isArray(connections) &&
                              connections.map((item) => (
                                <div key={item.name} className="item">
                                  <div className="left">
                                    <span className="connection">
                                      {item.label}
                                    </span>
                                    <Tooltip title={item.helpText}>
                                      <img src={infoIcon} alt="info" />
                                    </Tooltip>
                                  </div>
                                  <div className="right">{`${item.value}%`}</div>
                                </div>
                              ))}
                          </div>
                          {Array.isArray(risk.connections) &&
                            risk.connections.length > 5 && (
                              <button
                                type="button"
                                className="toggleButton"
                                onClick={toggleHandler}
                              >
                                <span>{open ? "Less" : "More"}</span>{" "}
                                <img
                                  src={open ? chevronUpIcon : chevronDownIcon}
                                  alt="chevron"
                                />
                              </button>
                            )}
                        </>
                      )}
                      {Object.keys(risk.connections).length === 0 && (
                        <div className="emptyList">
                          {RISK.noConnectionsMessage}
                        </div>
                      )}
                    </div>
                  </Panel>
                );
              }
            )}
          </Collapse>
        </Col>
      </Row>

      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Button onClick={downloadReport}>
            Download PureFi VC Certificate
          </Button>
        </Col>
        <Col span={24}>
          <Button onClick={() => {}} disabled>
            Go to PureFi dashboard
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ArrayRiskModal;
