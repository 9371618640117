import { ReactNode } from "react";
import clsx from "clsx";

import { Alert as AntdAlert } from "antd";

import styles from "./Alert.module.scss";

type AlertProps = {
  type: "success" | "info" | "warning" | "error";
  children: ReactNode;
};

const Alert = ({ type, children }: AlertProps) => (
  <AntdAlert
    type={type}
    message={
      <span className={styles.alertText} data-testid="alert-text">
        {children}
      </span>
    }
    className={clsx(styles.alert, styles[type])}
  />
);

export default Alert;
