import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { WalletProvider } from "context";
import { Layout } from "components";
import { Gleam } from "views";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <Router>
          <Layout>
            <Gleam />
          </Layout>
        </Router>
      </WalletProvider>
    </QueryClientProvider>
  );
}

export default App;
