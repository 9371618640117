import { Button, Row, Col } from "antd";

import { WalletType } from "models";
import { useWallet } from "hooks";

import { ReactComponent as MetaMaskLogo } from "assets/metamask.svg";
import { ReactComponent as WalletConnectLogo } from "assets/wallet-connect.svg";

import styles from "./WalletsModal.module.scss";

type WalletsModalProps = {
  onClose: () => void;
};

const WalletsModal = ({ onClose }: WalletsModalProps) => {
  const { connect } = useWallet();

  const handleMetamaskConnect = async () => {
    try {
      onClose();
      await connect(WalletType.metamask);
    } catch (error) {
      console.log(error);
    }
  };

  const handleWalletAccountConnect = async () => {
    try {
      onClose();
      await connect(WalletType.walletConnect);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h4 className={styles.title}>Connect Wallet</h4>

      <Row gutter={[16, 16]} justify="space-around">
        <Col span={12}>
          <Button
            type="text"
            className={styles.button}
            onClick={handleMetamaskConnect}
          >
            <MetaMaskLogo />
            <span className={styles.buttonText}>Metamask</span>
          </Button>
        </Col>

        <Col span={12}>
          <Button
            type="text"
            className={styles.button}
            onClick={handleWalletAccountConnect}
          >
            <WalletConnectLogo />
            <span className={styles.buttonText}>WalletConnect</span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default WalletsModal;
