import { ReactNode } from "react";

import { Modal as AntdModal } from "antd";

import styles from "./Modal.module.scss";

type ModalProps = {
  visible: boolean;
  onCancel: () => void;
  children: ReactNode;
  centered?: boolean;
  closable?: boolean;
  width?: number;
};

const Modal = ({
  visible,
  onCancel,
  children,
  centered = false,
  closable = true,
  width,
}: ModalProps) => (
  <AntdModal
    visible={visible}
    onCancel={onCancel}
    footer={null}
    className={styles.modal}
    maskClosable={false}
    centered={centered}
    closable={closable}
    destroyOnClose
    width={width || 570}
  >
    {children}
  </AntdModal>
);

export default Modal;
